module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"891209c3a437995b384d6b036bdb6680"},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website","titleTemplate":"%s - Bitnimbus.io","locale":"en_US","url":"https://www.bitnimbus.io","site_name":"Bitnimbus.io","images":[{"url":"https://bitnimbus-public-assets.us-east-1.linodeobjects.com/static/images/bitnimbus_logo.png","alt":"Bitnimbus.io logo"}]}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
